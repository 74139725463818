import React, {useEffect, useRef, useState} from 'react';
import "./Login.scss";
import {Button, FormControl, FormHelperText, TextField} from "@mui/material";
import {validate} from "../../utilities/validator";
import {post} from "../../utilities/api";
import {useNavigate} from "react-router-dom";
import {authenticate} from "../../utilities/authentication";

const Login = () => {

  const [input, setInput] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const validations = {
    "username": {
      "data_type": "string",
      "trim": true,
      "required": true
    },
    "password": {
      "data_type": "string",
      "required": true
    }
  }

  useEffect(() => {
    authenticate().then(result => result ? navigate("/admin"): "");
  }, []);

  const submit = async () => {
    const {data, errors} = validate(input, validations);
    setErrors(errors);
    if (Object.keys(errors).length !== 0)
      return;
    const {status, json} = await post("login", data);
    switch (status) {
      case 200:
        navigate("/admin");
        break;
      case 400:
        console.log(json["errors"]);
        setErrors(json["errors"]);
        break;
      case 401:
        setErrors({submit: json["error"]});
        break;
      default:
        setErrors({"submit": "An error has occurred, please contact your administrator."})
    }
  }



  return (
      <div className="Login">
        <h1>Nictar Administrator Login</h1>
        <FormControl className="login-form" sx={{ width: "350px"}}>
          <TextField id="outlined-basic" label="Username/Email" variant="outlined" helperText={errors["username"]} onChange={e => setInput({...input, username: e.target.value})}/>
          <TextField id="outlined-basic" label="Password" variant="outlined" type="password" helperText={errors["password"]} onChange={e => setInput({...input, password: e.target.value})}/>
          <Button variant="outlined" onClick={() => submit()}>Login</Button>
          <FormHelperText>{errors["submit"]}</FormHelperText>
        </FormControl>
      </div>
  );
};

export default Login;