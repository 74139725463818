import './App.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import Events from "./pages/Events";
import Membership from "./pages/Membership";
import Partnership from "./pages/Partnership";
import Layout from "./components/Layout";
import Configurator from "./pages/admin/Configurator";
import Login from "./pages/admin/Login";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Home/>}/>
            <Route path="about" element={<About/>}/>
            <Route path="products" element={<Products/>}/>
            <Route path="events" element={<Events/>}/>
            <Route path="life-club-membership" element={<Membership/>}/>
            <Route path="collaboration-partnership" element={<Partnership/>}/>
          </Route>
          <Route path="/admin">
            <Route index element={<Configurator/>}/>
            <Route path="login" element={<Login/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
