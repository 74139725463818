import React, {useEffect, useState} from 'react';
import "./About.scss";
import BannerImage from "../images/banners/banner-image.jpg";
import {get} from "../utilities/api";

const About = () => {

  const [replacement, setReplacement] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    get("replacement").then(({status, json}) => setReplacement(json));
  }, [])

  return (
      <>
        <div className="Banner">
          <div>
            <h1>About Nictar</h1>
            <p>{replacement["about-banner"]}</p>
          </div>
          <img src={BannerImage} alt=""/>
        </div>
        <div className="About">
          <div className="development">
            <h1>Nictar Singapore Development</h1>
            <div>
              <h2>2016</h2>
              <div>
                <div>
                  <p>Nictar Singapore Pte Ltd was founded on 30 June 2016 by</p>
                  <ul>
                    <li>Haleem Lim Yong Hwa (Nictar Malaysia Founder)</li>
                    <li>Alfred Goh Poon Soon</li>
                    <li>Martha Tan Peck Geok</li>
                    <li>Tina Wang Wei</li>
                  </ul>
                </div>
                <p>Participation in Singapore World Food Fair on 08 – 12 September 2016</p>
                <p>1st Farm Tour Nictar Nature Farm Visit on 06 November 2016</p>
                <p>Participation in Xchange Raffles Place, Singapore on 07 – 11 November 2016</p>
                <p>Products listing in Yue Hwa Departmental Store from June 2017</p>
              </div>
            </div>
            <div>
              <h2>2017</h2>
              <div>
                <p>Partner with Moove Media having ComfortDelgo Taxis projecting advertisement In June 2017</p>
                <p>Participation in China 14th China-ASEAN Expo on 10 - 13 September 2017 at Nanning, Guangxi, China</p>
              </div>
            </div>
            <div>
              <h2>2018</h2>
              <div>
                <p>Management re-alignment and having Ms Serene Mok Sheue Mei, the founder of Cafe@Smith and Flourish Culinary Pte Ltd, joining in May 2018</p>
                <p>Nictar products were listed in Hao Mart supermarket stores and Nice Minimart chain stores, Charis convenience stores from June 2018</p>
                <p>Onboard with Lazada and 99% SME Listed on Lazada Singapore Marketplace from 16 November 2018</p>
              </div>
            </div>
            <div>
              <h2>2020</h2>
              <div>
                <p>Covid-19 Outbreak, company operations paused</p>
              </div>
            </div>
            <div>
              <h2>2021</h2>
              <div>
                <p>Opening its own food stall in Clementi “Yuen Sin Kitchen” in August 2021</p>
              </div>
            </div>
            <div>
              <h2>2022</h2>
              <div>
                <p>Take over Flourish Culinary Pte Ltd in March 2022 to prepare roadmap for Bistro / Café business</p>
                <p>Re-alignment of company direction and group structure in August 2022</p>
              </div>
            </div>
          </div>
          <div className="about-us">
            <h1>About Us</h1>
            <div>
              <h2>Our Vision</h2>
              <p>{replacement["about-vision"]}</p>
            </div>
            <div>
              <h2>Our Principles</h2>
              <p>{replacement["about-principles"]}</p>
            </div>
          </div>
        </div>
      </>
  );
};

export default About;