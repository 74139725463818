
export const validate = (input, validations) => {
  let data = {};
  let errors = {};

  for (const [field, options] of Object.entries(validations)) {
    let value = input[field] ?? null;
    const field_name = options["field_name"] || field.replaceAll(/(?:^|_)([a-z])/g, (match, group) => " " + group.toUpperCase()).trim();

    // Skip locked fields
    if (options["locked"])
      continue;

    // Skip hidden fields if empty
    if (options["hidden"] && value === null)
      continue;

    // Handle null
    if (value === null) {
      if (options["required"])
        errors[field] = field_name + " cannot be empty";
      else
        data[field] = null;
      continue;
    }

    // Handle string
    if (options["data_type"] === "string") {

      // Check data type
      if (typeof value !== "string") {
        errors[field] = field_name + " must be a string";
        continue;
      }

      // Trim string
      if (options["trim"])
        value = value.replaceAll(/ +/g, " ").trim();

      // Skip password
      if (options["hidden"] && value === "")
        continue;

      // Check for empty string
      if (options["required"] && value === "") {
        errors[field] = field_name + " cannot be empty";
        continue;
      }

      // Check valid email
      if (options["email"] && !value.match(/^[A-z\d]+@[A-z\d]+\.[.A-z\d]+$/)) {
        errors[field] = field_name + " is invalid";
        continue;
      }

      // Check valid phone
      if (options["phone"] && !value.match(/[+ \-\d]+/)) {
        errors[field] = field_name + " is invalid"
        continue;
      }

      // Check max length
      let max = options["max"];
      if (Number.isInteger(max) && value.length > max) {
        errors[field] = field_name + " cannot be longer than " + max + " characters";
        continue;
      }

      // Check min length
      let min = options["min"];
      if (Number.isInteger(min) && value.length < min) {
        errors[field] = field_name + " cannot be shorter than " + min + " characters";
        continue;
      }

      // Check choices
      let choices = options["choices"];
      if (Array.isArray(choices) && choices.includes(value)) {
        errors[field] = "An invalid option is selected for " + field_name;
        continue;
      }
    }

    // Handle numbers
    if (options["data_type"] === "number") {

      // Check data type
      if (isNaN(value)) {
        errors[field] = field_name + " must be a number";
        continue;
      }

      value = +value;

      // Check max
      let max = options["max"];
      if (Number.isInteger(max) && value > max) {
        errors[field] = field_name + " must not be greater than " + max;
        continue;
      }

      // Check min
      let min = options["min"];
      if (Number.isInteger(min) && value < min) {
        errors[field] = field_name + " must not be lower than " + min;
        continue;
      }

    }

    // Handle boolean
    if (options["data_type"] === "boolean") {

      // Check data type
      if (typeof value !== "boolean") {
        value = false;
        // errors[field] = field_name + " must be a boolean";
        // continue;
      }

      // Check equals
      let equals = options["equals"];
      if (typeof equals === "boolean" && value !== equals) {
        errors[field] = field_name + " must be " + equals;
        continue;
      }
    }

    // Handle datetime
    if (options["data_type"] === "datetime") {

      // if (!(value instanceof Date)) {
      //   errors[field] = field_name + " must be a datetime value";
      //   continue;
      // }

      // To implement date range validation

    }

    // Handle list
    if (options["data_type"] === "list") {

      // Check data type
      if (!Array.isArray(value)) {
        value = value.split(",");
        value = value.map(item => isNaN(item) ? item: +item);
        //errors[field] = field_name + " must be a list";
        //continue;
      }

      // Check choices
      let choices = options["choices"];
      if (Array.isArray(choices) && !value.every(item => choices.includes(item))) {
        errors[field] = "Invalid option(s) are selected for " + field_name;
        continue;
      }
    }

    data[field] = value;
  }

  return {data: data, errors: errors}

}