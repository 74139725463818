import React, {useEffect, useMemo, useRef, useState} from 'react';
import "./Configurator.scss";
import {useNavigate} from "react-router-dom";
import {authenticate} from "../../utilities/authentication";
import {get, post, put} from "../../utilities/api";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem,
  Paper, Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import moment from "moment";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {validate} from "../../utilities/validator";

const Configurator = () => {

  const navigate = useNavigate();
  const [configuration, setConfiguration] = useState({});
  const [table, setTable] = useState("");
  const [tableData, setTableData] = useState("");
  const [input, setInput] = useState(null);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const fileRef = useRef();
  const widthMap = {
    "string": 100,
    "datetime": 100
  }

  useEffect(() => {
    authenticate().then(result => !result ? navigate("/admin/login") : "");
    get("configurator").then(({status, json}) => setConfiguration(json));
  }, []);

  useEffect(() => {
    if (table)
      get("configurator/" + table).then(({status, json}) => setTableData(json));
  }, [table, refresh]);

  const logout = () => {
    get("logout").then(() => navigate("/admin/login"));
  }

  const niceName = (name) => {
    return name.replaceAll(/(?:^|_)([a-z])/g, (match, group) => " " + group.toUpperCase()).trim();
  }

  const getFields = () => {
    return (
        <>
          {Object.entries(configuration[table]).map(([field, options]) => {
            if (options["hidden"] && options["locked"])
              return;
            return <TableCell key={field} sx={{minWidth: options["width"] || widthMap[options["data_type"]]}}>{options["field_name"] || niceName(field)}</TableCell>;
          })}
        </>
    )
  }

  const changeInput = (field, value) => {
    setInput({...input, [field]: value});
  }

  const uploadFile = async (field, files) => {
    let uploaded = [];
    for (const file of files) {
      let formData = new FormData();
      formData.append("file", file);
      const response = await fetch(process.env.REACT_APP_SERVER_URL + "upload", {method: "POST", body: formData, credentials: "include"});
      const json = await response.json();
      uploaded.push(json["filename"]);
    }
    setInput({...input, [field]: [...(Array.isArray(input[field]) ? input[field] : (input[field] ? input[field].split(",") : [])), ...uploaded]});
  }

  const submit = async () => {
    const {data, errors} = validate(input, configuration[table]);
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }
    if (editMode === "EDIT")
      data["id"] = input["id"];
    const {status, json} = editMode === "ADD" ? await post("configurator/" + table, data): await put("configurator/" + table, data);
    console.log(status);
    console.log(json);
    switch (status) {
      case 200:
        setInput(null);
        setRefresh(!refresh);
        break;
      case 400:
        setErrors(json["errors"] || {submit: json["error"]});
        break;
      default:
        setErrors({"submit": "An error has occurred, please contact the administrator"});
    }
  }

  const getValues = (row) => {
    return (
        <>
          {Object.entries(configuration[table]).map(([field, options]) => {
              if (options["hidden"] && options["locked"])
                return;
              let value = row[field];
              if (!value)
                return <TableCell key={field + "-" + row["id"]}></TableCell>
              if (options["data_type"] === "list")
                return <TableCell key={field + "-" + row["id"]}><div className="list">{value.split(",").map(item => <p key={item}>{(options["labels"] || {})[item] || item}</p>)}</div></TableCell>;
              if (options["data_type"] === "boolean")
                return <TableCell key={field + "-" + row["id"]}><Checkbox disabled checked={value}/></TableCell>;
              if (options["data_type"] === "datetime")
                return <TableCell key={field + "-" + row["id"]}>{moment(value, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY hh:mm A")}</TableCell>
              return <TableCell key={field + "-" + row["id"]}>{value}</TableCell>;
          })}
        </>
    )
  }

  const getSidebar = () => {
    return (
        <div className="sidebar">
          {Object.keys(configuration).map(key => <p key={key} onClick={() => setTable(key)} className={table === key ? "active": ""}>{niceName(key)}</p>)}
          <ArrowRightIcon className="arrow"/>
        </div>
    )
  }

  const getTable = () => {
    // noinspection JSValidateTypes
    return (
        <div className="main">
          <div className="top">
            <h1>{niceName(table)}</h1>
            <Button variant="outlined" onClick={() => {setInput({}); setEditMode("ADD");}}>Add</Button>
          </div>
          <TableContainer className="table" component={Paper}>
            <Table stickyHeader sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  {getFields()}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map(row => (
                  <TableRow key={row["id"]} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {getValues(row)}
                    <TableCell>
                      <Button variant="outlined" onClick={() => {setInput(row); setEditMode("EDIT");}}>Edit</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {input !== null ? getForm(): ""}
        </div>
    )
  }

  const getForm = () => {
    return (
        <div className="form">
          <div>
            {
              Object.entries(configuration[table]).map(([field, options]) => {

                // Get attributes
                const locked = options["locked"];
                const hidden = options["hidden"];
                const required = options["required"];
                const data_type = options["data_type"];

                // Skip if locked and add mode
                if (locked && (hidden || editMode === "ADD"))
                  return;

                // Get field label
                let label = options["field_name"] || niceName(field);
                // Add * for required, except hidden and not locked and edit
                label += required && !(hidden && !locked && editMode === "EDIT") ? " *" : "";

                // Get value
                let value = input[field];

                // Select widget
                const choices = options["choices"];
                if (choices) {

                  // Check if multi-select
                  const multiple = data_type === "list";

                  // Convert multiple values into array
                  if (multiple) {

                    if (value && !Array.isArray(value))
                      value = value.split(",").map(item => isNaN(item) ? item : +item);

                    if (!value)
                      value = [];
                  }

                  const choiceLabels = options["labels"];

                  return <FormControl key={field}>
                    <InputLabel>{label}</InputLabel>
                    <Select label={label} value={value} multiple={multiple} disabled={locked}
                            onChange={e => changeInput(field, e.target.value)}>
                      {choices.map(choice => <MenuItem key={choice} value={choice}>{choiceLabels ? choiceLabels[choice] : choice}</MenuItem>)}
                    </Select>
                    <FormHelperText>{errors[field]}</FormHelperText>
                  </FormControl>
                }

                // Text Field widget
                if (data_type === "string" || data_type === "number" || (data_type === "datetime" && locked)) {

                  // Format datetime
                  if (data_type === "datetime")
                    value = moment(value, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm A");

                  const multiline = options["widget"] === "multiline";
                  const type = data_type === "number" ? "number" : "text";

                  return <TextField variant="outlined" label={label} value={value} multiline={multiline}
                                    disabled={locked} type={type} helperText={errors[field]}
                                    onChange={e => changeInput(field, e.target.value)}/>
                }


                // Checkbox widget
                if (data_type === "boolean") {
                  return <FormControl>
                    <FormControlLabel control={<Checkbox checked={value} options={locked}
                                                         onChange={e => changeInput(field, e.target.checked)}/>}
                                      label={label}/>
                    <FormHelperText>{errors[field]}</FormHelperText>
                  </FormControl>
                }

                // Datetime widget
                if (data_type === "datetime") {
                  if (value === undefined)
                    value = null;
                  if (value)
                    value = moment(value, "YYYY-MM-DD HH:mm:ss");
                  return <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker onChange={value => changeInput(field, value ? value.toDate() : null)}
                                    value={value}
                                    inputFormat="DD MMM YYYY HH:mm A"
                                    disableMaskedInput
                                    renderInput={(params) => <TextField {...params} label={label}
                                                                        helperText={errors[field]}/>}
                    />
                  </LocalizationProvider>
                }

                // File upload widget
                if (options["widget"] === "files") {
                  const multiple = data_type === "list";
                  // Get existing files
                  if (multiple) {
                    if (!Array.isArray(value)) {
                      value = value ? value.split(",") : [];
                    }
                  }

                  return <div className="file-upload">
                    <p>{label}</p>
                    <div className="file-list">
                      <Button variant="outlined" onClick={() => fileRef.current.click()}>Upload</Button>
                      <input type="file" id="file" ref={fileRef} multiple={multiple} onChange={e => uploadFile(field, e.target.files)}/>
                      {value.map(item => <div>
                        <img src={process.env.REACT_APP_SERVER_URL + "upload/" + item} alt={item}/>
                        <p>{item}</p>
                        <CloseIcon onClick={() => {
                          const files = value.filter(filename => item !== filename);
                          changeInput(field, files);
                        }}/>
                      </div>)}
                      <FormHelperText>{errors[field]}</FormHelperText>
                    </div>
                  </div>
                }
              })
            }
            <div className="form-actions">
              <Button variant="outlined" onClick={() => submit()}>Save</Button>
              <Button variant="outlined" onClick={() => setInput(null)}>Cancel</Button>
            </div>
            <FormHelperText>{errors["submit"]}</FormHelperText>
          </div>
        </div>
    )
  }

  return (
      <div className="Configurator">
        <div className="header">
          <h1>Nictar Administrator Panel</h1>
          <p onClick={() => logout()}>Logout</p>
        </div>
        {getSidebar()}
        {table && tableData ? getTable(): ""}
      </div>
  );
};

export default Configurator;