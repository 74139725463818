import React, {useEffect, useState} from 'react';
import "./Events.scss";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {get} from "../utilities/api";

const Events = () => {

  const [events, setEvents] = useState([]);

  useEffect(() => {
    get("news").then(({status, json}) => setEvents(json));
  }, []);

  return (
      <div className="Events">
        <Carousel
            className="gallery"
            infiniteLoop
            preventMovementUntilSwipeScrollTolerance
            showStatus={false}
            swipeScrollTolerance={50}
            showThumbs={false}
            autoPlay={true}
        >
          {events.map(event => event["images"].split(",").map(image =>
              <div key={event["id"]} className="gallery-item">
                <img src={process.env.REACT_APP_SERVER_URL + "upload/" + image} alt={event["title"]}/>
                <div className="event-info">
                  <h1>{event["title"]}</h1>
                </div>
              </div>
          ))}
        </Carousel>
        <div className="events-list">
          {events.map(event => <div>
            <h1>{event["title"]}</h1>
            <p>{event["content"]}</p>
            <Carousel
              infiniteLoop
              preventMovementUntilSwipeScrollTolerance
              showStatus={false}
              swipeScrollTolerance={50}
              showThumbs={false}
              autoPlay={true}
            >
              {event["images"].split(",").map(image => <img src={process.env.REACT_APP_SERVER_URL + "upload/" + image} alt=""/>)}
            </Carousel>
          </div>)}
        </div>
      </div>
      // <>
      //   <div className="Banner">
      //     <div>
      //       <h1>Events</h1>
      //       <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus cum eaque earum eius error facere facilis fugit harum incidunt neque nihil nobis non odio, odit provident quidem quod temporibus, voluptatibus. Autem cumque eius molestias quis quod repellendus saepe sit, voluptatum!</p>
      //     </div>
      //     <img src={BannerImage} alt=""/>
      //   </div>
      //   <div className="Events">
      //     <h1>Our Events</h1>
      //     <div>
      //       <h2>2016 Singapore World Food Fair</h2>
      //       <Carousel
      //           emulateTouch
      //           infiniteLoop
      //           preventMovementUntilSwipeScrollTolerance
      //           showStatus={false}
      //           swipeScrollTolerance={50}
      //       >
      //         <img src={world0} alt=""/>
      //       </Carousel>
      //     </div>
      //     <div>
      //       <h2>2016 CBD Xchange Food Fair</h2>
      //       <Carousel
      //           emulateTouch
      //           infiniteLoop
      //           preventMovementUntilSwipeScrollTolerance
      //           showStatus={false}
      //           swipeScrollTolerance={50}
      //       >
      //         <img src={xchange0} alt=""/>
      //         <img src={xchange1} alt=""/>
      //         <img src={xchange2} alt=""/>
      //         <img src={xchange3} alt=""/>
      //       </Carousel>
      //     </div>
      //     <div>
      //       <h2>2017 China-ASEAN Expo</h2>
      //       <Carousel
      //           emulateTouch
      //           infiniteLoop
      //           preventMovementUntilSwipeScrollTolerance
      //           showStatus={false}
      //           swipeScrollTolerance={50}
      //       >
      //         <img src={expo0} alt=""/>
      //         <img src={expo1} alt=""/>
      //         <img src={expo2} alt=""/>
      //         <img src={expo3} alt=""/>
      //         <img src={expo4} alt=""/>
      //         <img src={expo5} alt=""/>
      //         <img src={expo6} alt=""/>
      //         <img src={expo7} alt=""/>
      //       </Carousel>
      //     </div>
      //     <div>
      //       <h2>2017 Yue Hua Food Fair</h2>
      //       <Carousel
      //           emulateTouch
      //           infiniteLoop
      //           preventMovementUntilSwipeScrollTolerance
      //           showStatus={false}
      //           swipeScrollTolerance={50}
      //       >
      //         <img src={yuehwa0} alt=""/>
      //       </Carousel>
      //     </div>
      //   </div>
      // </>
  );
};

export default Events;