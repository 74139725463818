import React, {useEffect} from 'react';
import "./Products.scss";
import ProductsImage from "../images/products/products.jpg";
import PineappleJuiceImage from "../images/products/pineapple-juice.png";
import SweetAndSourPineappleSauceImage from "../images/products/sweet-and-sour-pineapple-sauce.jpg";
import SpicyPineappleSauceImage from "../images/products/spicy-pineapple-sauce.jpg";
import PineappleChilliSauceImage from "../images/products/pineapple-chilli-sauce.png";
import PineappleCookieImage from "../images/products/pineapple-cookie.jpg";
import PineappleJamImage from "../images/products/pineapple-jam.png";
import BannerImage from "../images/banners/banner-image.jpg";

const Products = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
      <>
        <div className="Banner">
          <div>
            <h1>Products</h1>
            <ul>
              <li>Nictar Pure Pineapple Juice</li>
              <li>Nictar Sweet & Sour Pineapple Sauce</li>
              <li>Nictar Spicy Pineapple Sauce</li>
              <li>Nictar Pineapple Chilli Sauce</li>
              <li>Nictar Pineapple Cookie</li>
              <li>Nictar Pineapple Jam</li>
            </ul>
          </div>
          <img src={BannerImage} alt=""/>
        </div>
        <div className="Products">
          <div className="intro">
            <h1>Our Products</h1>
            <div>
              <img src={ProductsImage} alt="Products"/>
              <div>
                <p>Nictar is a brand of REAL FOODS. Its products are all farm fresh, exercising conscience principle in their processes and ingredients.</p>
                <p>The following three artificial substances are omitted from the products</p>
                <ul>
                  <li>Preservatives</li>
                  <li>Colours</li>
                  <li>Flavours</li>
                </ul>
                <p>We strictly maintain the above to produce a range of exotic tropical food products to serve the Malaysian consumers.</p>
              </div>
            </div>
          </div>
          <div className="product-list">
            <div>
              <img src={PineappleJuiceImage} alt="Nictar Pure Pineapple Juice"/>
              <div>
                <h2>Nictar Pure Pineapple Juice</h2>
                <p>Nictar Pineapple Juice is squeezed from a special variety of Malaysian pineapple Morris. The non GMO fruit juice contains the natural sweet and sour taste of pineapple juice, together it brings in good mouthfeel and good flavour.</p>
                <p>All the natural fibre and nutrients are perfectly retained in the natural juice. The juice will vitalize you and bring in good health.</p>
              </div>
            </div>
            <div>
              <img src={SweetAndSourPineappleSauceImage} alt="Nictar Sweet & Sour Pineapple Sauce"/>
              <div>
                <h2>Nictar Sweet & Sour Pineapple Sauce</h2>
                <p>Prepared using home recipe, contains ripen pineapples with lemon, calamansi and stewed under slow fire, the Sweet & Sour Pineapple Sauce enables the preparation of pineapple related dishes an easy tasks.</p>
                <p> Besides to use as a salad sauce for fruits, vegetables, toufu and meat, it can be used to bake pineapple biscuits and cakes. The pleasant sweet sour fragrance of pineapple fruit pieces will excite the taste buds of both children and adults encouraging good appetite to want more.</p>
              </div>
            </div>
            <div>
              <img src={SpicyPineappleSauceImage} alt="Nictar Spicy Pineapple Sauce"/>
              <div>
                <h2>Nictar Spicy Pineapple Sauce</h2>
                <p>The Spicy Pineapple sauce is widely used in Malay cuisine, made from pineapple blended with natural spices and cooked over slow fire.</p>
                <p>Nictar Pineapple Spicy Sauce is manufactured from our secret recipe which goes very well with toufu dipping, steam or deep fried with vegetables and fish, incidentally, it can be used as a sauce for blending noodles.</p>
                <p>Our sauce contains genuine juicy pineapple pulps with its pleasant sweet our taste, together with natural chilly and spicy condiments, will give you a memorable taste one never experience before.</p>
              </div>
            </div>
            <div>
              <img src={PineappleChilliSauceImage} alt="Nictar Pineapple Chilli Sauce"/>
              <div>
                <h2>Nictar Pineapple Chilli Sauce</h2>
                <p>This chilli sauce has combined the unique Malaysian pineapple, chilli and freshly squeezed calamansi resulting in an exciting taste profile. Owing to its unique taste profile, our product stands out on its own and is different from the rest in the market, and will excite the sauce market.</p>
                <p>The sauce is suitable to be used as a dipping or for culinary use. Every kitchen should equipped with this sauce for routine cooking and it is great fun to be used in barbeque party too.</p>
              </div>
            </div>
            <div>
              <img src={PineappleCookieImage} alt="Nictar Pineapple Cookie"/>
              <div>
                <h2>Nictar Pineapple Cookie</h2>
                <p>Every cookie is made exactly in the style of our grandmother, each contains rich pineapple filling came from genuine Pontian pineapple pulp. Besides, fresh calamansi juices are added to enhance the flavour. The cookie filling is prepared by cooking over slow fire for 10 hours, ensuring full flavour penetration.</p>
                <p>The handmade crust will be easily melt in your mouth maximizing your mouthfeel satisfaction.</p>
              </div>
            </div>
            <div>
              <img src={PineappleJamImage} alt="Nictar Pineapple Jam"/>
              <div>
                <h2>Nictar Pineapple Jam</h2>
                <p>Developed in the Malaysian traditional kitchen and local menu, Nictar Pineapple Jam with its natural calamansi component will bring in the rich tropical flavour, whether you use it on cakes, candy or in culinary dishes.</p>
                <p>Its unique high fruit pulp content and relatively low calories recipe will ensure your dishes taste and look great apetizing as well as good for health.</p>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default Products;