
export const post = async (path, data) => {
  const response = await fetch(
      process.env.REACT_APP_SERVER_URL + path,
      {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data),
        credentials: "include"
      }
  )
  return {status: response.status, json: await response.json()}
}

export const put = async (path, data) => {
  const response = await fetch(
      process.env.REACT_APP_SERVER_URL + path,
      {
        method: "PUT",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data),
        credentials: "include"
      }
  )
  return {status: response.status, json: await response.json()}
}

export const get = async (path) => {
  const response = await fetch(
      process.env.REACT_APP_SERVER_URL + path,
      {
        credentials: "include"
      }
  );
  return {status: response.status, json: await response.json()}
}

export const getImage = async (path) => {
  const response = await fetch(
      process.env.REACT_APP_SERVER_URL + path,
      {
        credentials: "include"
      }
  );
  return {status: response.status, blob: await response.blob()}
}