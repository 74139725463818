import React, {useEffect, useState} from 'react';
import "./Header.scss";
import {Link, NavLink} from "react-router-dom";
import Logo from "../images/nictar-logo.png";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import FacebookIcon from '@mui/icons-material/Facebook';


const Header = () => {

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(false);

  const scrollListener = () => {
    setScroll(window.scrollY > 20);
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);
    return () => window.removeEventListener("scroll", scrollListener);
  }, []);

  return (
      <div className={"Header" + (scroll || open ? " scroll": "")}>
        <Link to="/" className="logo">
          <img src={Logo} alt="home"/>
        </Link>
        <div className="menu" onClick={() => setOpen(!open)}>
          {open ? <MenuOpenIcon/>: <MenuIcon/>}
        </div>
        <div className={"links" + (open ? " open": "")} onClick={() => setOpen(false)}>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About Nictar</NavLink>
          <NavLink to="/products">Products</NavLink>
          <NavLink to="/events">Events</NavLink>
          {/*<NavLink to="/">Programs</NavLink>*/}
          {/*<NavLink to="/">Brand</NavLink>*/}
          {/*<NavLink to="/">Experience</NavLink>*/}
          <div className="sub-menu">
            <p>Community</p>
            <div>
              <a href="https://forms.gle/skh8Y3BpHGrZSvWB7" target="_blank">Life Club Membership</a>
              <a href="https://forms.gle/KdGZ9RQjHDF1CEKXA" target="_blank">Collaboration Partnership</a>
            </div>
          </div>
          <div className="contact">
            <PlaceIcon/>
            <p>201 Henderson Road, #08-28</p>
            <p></p>
            <p>Apex@Henderson</p>
            <p></p>
            <p>Singapore 159545</p>
            <PhoneIcon/>
            <a href="tel:+65 8484 6538">+65 8484 6538</a>
            <EmailIcon/>
            <a href="mailto:nictar.global@gmail.com">nictar.global@gmail.com</a>
            <FacebookIcon/>
            <a href="https://www.facebook.com/Nictarpineapplepark/">Follow us on facebook</a>
          </div>
        </div>
      </div>
  );
};

export default Header;