import React from 'react';
import "./Partnership.scss";
import BannerImage from "../images/banners/banner-image.jpg";

const Partnership = () => {
  return (
      <>
        <div className="Banner">
          <div>
            <h2>Collaboration Partnership</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus cum eaque earum eius error facere facilis fugit harum incidunt neque nihil nobis non odio, odit provident quidem quod temporibus, voluptatibus. Autem cumque eius molestias quis quod repellendus saepe sit, voluptatum!</p>
          </div>
          <img src={BannerImage} alt=""/>
        </div>
        <div className="Partnership">

        </div>
      </>
  );
};

export default Partnership;