import React, {useEffect, useState} from 'react';
import {Tree, TreeNode} from "react-organizational-chart";
import "./Home.scss";
import {get} from "../utilities/api";
import BannerImage from "../images/banners/banner-image.jpg";

const Home = () => {

  const [dailyMessage, setDailyMessage] = useState(null);
  const defaultMessage = "Good day! Welcome to Nictar. Hope you're having a fantastic day!";

  useEffect(() => {
    get("daily_message").then(({json}) => setDailyMessage(json["daily_message"] || defaultMessage));
  }, [])

  return (
      <>
        <div className="Banner">
          <div>
            <h1>Nictar</h1>
            <ul>
              <li>No Artificial Preservatives</li>
              <li>No Artificial Colouring</li>
              <li>No Artificial Flavour Elements</li>
            </ul>
          </div>
          <img src={BannerImage} alt=""/>
        </div>
        <div className="Home">
          <p className="daily-message">Daily Message: {dailyMessage}</p>
          <div className="origin">
            <h1>Nictar Origin</h1>
            <p>Nictar was originally from Malaysia, Pontian, named Nictar Natural Resources Sdn Bhd, established as a family farm base manufacturer of pineapple related food and beverages. The founder and key men of Nictar adopt the oriental philosophy of life in their business: Humanity and Conscience. For so many years, Nictar adheres to the three “No” principle:</p>
            <ul>
              <li>No Artificial Preservatives</li>
              <li>No Artificial Coloring</li>
              <li>No Artificial Flavour Elements</li>
            </ul>
            <p>Nictar Malaysia owns a Farm (Nictar Farm) at Parit Sikom and a retail shop at Pontian town centre.</p>
          </div>
          <div className="structure">
            <h1>Nictar Group Structure</h1>
            <div className="tree">
              <Tree label={<div>Nictar Holding</div>}
                    lineColor={"#fa8c16"}
                    lineWidth={"5px"}
                    lineHeight={"40px"}
                    lineBorderRadius={"16px"}
              >
                <TreeNode label={<div>F&B</div>}>
                  <TreeNode label={<div>Yuen Sin Kitchen</div>}/>
                  <TreeNode label={<div>Retail Pineapple Food and Beverage</div>}/>
                  <TreeNode label={<div>Flourish Culinary</div>}/>
                </TreeNode>
                <TreeNode label={<div>Media/Event Production</div>}/>
                <TreeNode label={<div>Lifestyle</div>}>
                  <TreeNode label={<div>Cattleya Moments</div>}/>
                  <TreeNode label={<div>Fantastic Training</div>}/>
                </TreeNode>
              </Tree>
            </div>
          </div>
        </div>
      </>
  );
};

export default Home;