import React from 'react';
import "./Footer.scss";
import Logo from "../images/nictar-logo.png";
import {NavLink} from "react-router-dom";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";

const Footer = () => {
  return (
      <div className="Footer">
        <img src={Logo} alt="logo"/>
        <div className="info">
          <div className="links">
            <h6>Quick Links</h6>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/about">About Nictar</NavLink>
            <NavLink to="/products">Products</NavLink>
            <NavLink to="/events">Events</NavLink>
            <a href="https://forms.gle/skh8Y3BpHGrZSvWB7" target="_blank">Life Club Membership</a>
            <a href="https://forms.gle/KdGZ9RQjHDF1CEKXA" target="_blank">Collaboration Partnership</a>
          </div>
          <div className="contact">
            <PlaceIcon/>
            <p>201 Henderson Road, #08-28</p>
            <p></p>
            <p>Apex@Henderson</p>
            <p></p>
            <p>Singapore 159545</p>
            <PhoneIcon/>
            <a href="tel:+65 8484 6538">+65 8484 6538</a>
            <EmailIcon/>
            <a href="mailto:nictar.global@gmail.com">nictar.global@gmail.com</a>
            <FacebookIcon/>
            <a href="https://www.facebook.com/Nictarpineapplepark/">Follow us on facebook</a>
          </div>
        </div>
        <hr/>
        <p>&copy; {new Date().getFullYear()} Nictar Singapore Pte Ltd.  All rights reserved.</p>
      </div>
  );
};

export default Footer;